<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informations de paiement
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Mettez à jour vos informations de paiement</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Sauvegarder
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Annuler
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <p>Votre carte est en cours de vérification...</p>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
export default {
  name: "Payment",
  methods: {
    save() {
      // var name = this.$refs.name.value;
      // var surname = this.$refs.surname.value;
      // var company_name = this.$refs.company_name.value;
      // var phone = this.$refs.phone.value;
      // var email = this.$refs.email.value;
      // var company_site = this.$refs.company_site.value;
      // var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        // this.$store.dispatch(UPDATE_PERSONAL_INFO, {
        //   name,
        //   surname,
        //   company_name,
        //   phone,
        //   email,
        //   company_site,
        //   photo
        // });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    }
  }
};
</script>
